import "core-js/modules/es6.object.assign";
import React from 'react';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import ScriptTag from 'react-script-tag';
import _ from 'lodash';
export default function (html) {
  if (!html) {
    return null;
  }

  return ReactHtmlParser(html, {
    transform: function transform(node, index) {
      if (node.type === 'script') {
        console.log(node);

        if (!_.isEmpty(node.children)) {
          return React.createElement(ScriptTag, Object.assign({
            key: index
          }, node.attribs), _.map(node.children, function (childNode) {
            return convertNodeToElement(childNode, index, _.noop());
          }));
        } else {
          return React.createElement(ScriptTag, Object.assign({
            key: index
          }, node.attribs));
        }
      }
    }
  });
}
;