import "core-js/modules/es6.string.starts-with";

var _require = require("gatsby"),
    withPrefix = _require.withPrefix;

var _ = require('lodash');

export default function (url) {
  if (_.startsWith(url, '#') || _.startsWith(url, 'http')) {
    return url;
  }

  return withPrefix(url);
}