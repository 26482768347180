import "core-js/modules/es6.object.assign";
import React from "react";
import InternalPageRenderer from "./page-renderer";

var ProdPageRenderer = function ProdPageRenderer(_ref) {
  var location = _ref.location,
      pageResources = _ref.pageResources;

  if (!pageResources) {
    return null;
  }

  return React.createElement(InternalPageRenderer, Object.assign({
    location: location,
    pageResources: pageResources
  }, pageResources.json));
};

export default ProdPageRenderer;