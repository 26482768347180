import Footer from './Footer';
import Header from './Header';
import Intro from './Intro';
import Social from './Social';
import Subscribe from './Subscribe';
import SubscribeForm from './SubscribeForm';
import Layout from './Layout';
export { Footer, Header, Intro, Social, Subscribe, SubscribeForm, Layout };
export default {
  Footer: Footer,
  Header: Header,
  Intro: Intro,
  Social: Social,
  Subscribe: Subscribe,
  SubscribeForm: SubscribeForm,
  Layout: Layout
};