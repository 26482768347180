import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from 'react';
import { Link as GatsbyLink } from "gatsby"; // Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink

var Link = function Link(_ref) {
  var children = _ref.children,
      to = _ref.to,
      activeClassName = _ref.activeClassName,
      partiallyActive = _ref.partiallyActive,
      other = _objectWithoutPropertiesLoose(_ref, ["children", "to", "activeClassName", "partiallyActive"]);

  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  var internal = /^\/(?!\/)/.test(to); // Use Gatsby Link for internal links, and <a> for others

  if (internal) {
    return React.createElement(GatsbyLink, Object.assign({
      to: to,
      activeClassName: activeClassName,
      partiallyActive: partiallyActive
    }, other), children);
  }

  return React.createElement("a", Object.assign({
    href: to
  }, other), children);
};

export default Link;